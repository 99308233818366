import React from "react";
import { ProCard } from "@ant-design/pro-components";
import { Row, Col, Divider } from "antd";
import RecentOrder from "./RecentOrder";
import FIXMonitor from "./FIXMonitor";
import OptionsTrading from "./OptionsTrading";

const Options = () => {
  return (
    <>
      <Row gutter={15}>
        <Col span={12}>
          <ProCard bordered style={{ height: "650px" }}>
            <h1>OPTIONS</h1>
          </ProCard>
        </Col>
        <Col span={12}>
          <ProCard bordered style={{ height: "650px" }}>
            <FIXMonitor />
          </ProCard>
        </Col>
      </Row>
      <Divider />
      <RecentOrder />
    </>
  );
};

export default Options;
