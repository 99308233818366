import React, { useState, useRef } from "react";
import { Form, Input, Radio, Space } from "antd";

const OrderQty = () => {
  return (
    <Form.Item name="OrderQty" label="Quentity" rules={[{ required: true }]}>
      <Input placeholder="Input Quentity" style={{ width: "100%" }} />
    </Form.Item>
  );
};

const Notional = () => {
  return (
    <Form.Item name="CashOrderQty" label="Notional">
      <Input placeholder="Input Notional Value" style={{ width: "100%" }} />
    </Form.Item>
  );
};

const TimeInForce = () => {
  const [tif, setTif] = useState("0");
  return (
    <>
      <Form.Item name="TimeInForce" label="Duration">
        <Radio.Group value={(e) => e.target.value} onChange={(e) => setTif(e.target.value)}>
          <Space size="middle">
            <Radio value="0">DAY</Radio>
            <Radio value="1">GTC</Radio>
            <Radio value="6">GTD</Radio>
            <Radio value="3">IOC</Radio>
            <Radio value="4">FOK</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item hidden={!(tif === "6")} name="ExpDate" label="GTD Date">
        <Input placeholder="Format: YYYYMMDD" style={{ width: "100%" }} />
      </Form.Item>
    </>
  );
};

const ExecInst = () => {
  return (
    <Form.Item name="ExecInst" label="Fill Type">
      <Radio.Group
        onChange={(e) => {
          console.log(e.target.value);
        }}
        value={(e) => e.target.value}
      >
        <Radio value="">ANY</Radio>
        <Radio value="G">AON</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

const Price = () => {
  return (
    <Form.Item name="Price" label="Price" rules={[{ required: true }]}>
      <Input placeholder="Price" style={{ width: "100%" }} />
    </Form.Item>
  );
};

const StopPx = () => {
  return (
    <Form.Item name="StopPx" label="Stop Price" rules={[{ required: true }]}>
      <Input placeholder="Stop Price" style={{ width: "100%" }} />
    </Form.Item>
  );
};

export const Market = () => {
  return (
    <>
      <OrderQty />
      <TimeInForce />
      {/* <ExecInst /> */}
    </>
  );
};

export const Limit = () => {
  return (
    <>
      <Price />
      <OrderQty />
      <TimeInForce />
      {/* <ExecInst /> */}
    </>
  );
};

export const Stop = () => {
  return (
    <>
      <StopPx />
      <OrderQty />
      <TimeInForce />
      {/* <ExecInst /> */}
    </>
  );
};

export const StopLimit = () => {
  return (
    <>
      <Price />
      <StopPx />
      <OrderQty />
      <TimeInForce />
      {/* <ExecInst /> */}
    </>
  );
};

export const TrailingStop = () => {
  return <></>;
};

export const NotionalMarket = () => {
  return (
    <>
      <Notional />
    </>
  );
};

export const NotionalLimit = () => {
  return (
    <>
      <Notional />
      <Price />
    </>
  );
};

export const MOO = () => {
  return (
    <>
      <OrderQty />
    </>
  );
};

export const MOC = () => {
  return (
    <>
      <OrderQty />
    </>
  );
};

export const LOO = () => {
  return (
    <>
      <Price />
      <OrderQty />
      {/* <ExecInst /> */}
    </>
  );
};

export const LOC = () => {
  return (
    <>
      <Price />
      <OrderQty />
      {/* <ExecInst /> */}
    </>
  );
};

export const TWAP = () => {
  return <></>;
};

export const VWAP = () => {
  return <></>;
};

export const POV = () => {
  return <></>;
};
