import React, { useState } from "react";
import { Form, Button, Select, Input, Space, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { newOrder } from "../api/OrderAPI";
import {
  Market,
  Limit,
  Stop,
  StopLimit,
  MOC,
  LOC,
  TrailingStop,
  NotionalMarket,
  NotionalLimit,
  MOO,
  LOO,
  TWAP,
  VWAP,
  POV,
} from "../ordtype/Types";
import { SideSelect, OrdTypeSelect, MyOrdTypeSelect, SessionSelect } from "../Selection/Select";

const NewOrder = () => {
  const [OrdType, setOrdType] = useState("");
  const queryClient = useQueryClient();
  const createOrderMutation = useMutation({
    mutationFn: newOrder,
    onSuccess: (data, variables, context) => {
      // queryClient.setQueryData(["recent_orders"], data);
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["recent_orders"] }, { exact: true }); // Invalidate query
      }, 13000);
    },
  });
  const [tradingForm] = Form.useForm();

  const tradingFormOnFinish = () => {
    let order = { ...tradingForm.getFieldsValue(), Account: process.env.REACT_APP_ACCOUNT_NUMBER, OrdType: OrdType };
    delete order.MyOrdType;
    if (!order.hasOwnProperty("OrderQty")) {
      order = { ...order, OrderQty: 1 };
    }
    createOrderMutation.mutate(order);
    tradingForm.resetFields();
  };

  return (
    <>
      <Form
        form={tradingForm}
        size="large"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        requiredMark={false}
        onFinish={tradingFormOnFinish}
        onFinishFailed={() => message.error("Place Order Error!")}
        initialValues={{
          // Symbol: "AAPL",
          // Side: "1",
          // OrdType: "1",
          // Price: 30.5,
          // OrderQty: 100,
          TimeInForce: "0",
          TradingSessionID: "CORE",
        }}
      >
        <Form.Item name="Symbol" label="Symbol" rules={[{ required: true }]}>
          <Input placeholder="Input Trading Symbol" />
        </Form.Item>
        <Form.Item name="Side" label="Side" rules={[{ required: true }]}>
          <Select options={SideSelect} placeholder="Select Side" />
        </Form.Item>

        <Form.Item name="MyOrdType" label="Order Type" rules={[{ required: true }]}>
          <Select options={MyOrdTypeSelect} placeholder="Select Order Type" />
        </Form.Item>
        {/* <Form.Item hidden name="OrdType" label="Order Type" rules={[{ required: true }]}>
          <Select options={OrdTypeSelect} placeholder="Select Order Type" />
        </Form.Item> */}

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
          {({ getFieldValue }) => {
            if (getFieldValue("MyOrdType") === "1") {
              setOrdType("1");
              return <Market />;
            } else if (getFieldValue("MyOrdType") === "2") {
              setOrdType("2");
              return <Limit />;
            } else if (getFieldValue("MyOrdType") === "3") {
              setOrdType("3");
              return <Stop />;
            } else if (getFieldValue("MyOrdType") === "4") {
              setOrdType("4");
              return <StopLimit />;
            } else if (getFieldValue("MyOrdType") === "5") {
              setOrdType("5");
              return <MOC />;
            } else if (getFieldValue("MyOrdType") === "B") {
              setOrdType("8");
              return <LOC />;
            } else if (getFieldValue("MyOrdType") === "P") {
              setOrdType("P");
              return <TrailingStop />;
            } else if (getFieldValue("MyOrdType") === "NM") {
              setOrdType("1");
              return <NotionalMarket />;
            } else if (getFieldValue("MyOrdType") === "NL") {
              setOrdType("2");
              return <NotionalLimit />;
            }
            // } else if (getFieldValue("OrdType") === "Market On Open") {
            //   return <MOO />;
            // }  else if (getFieldValue("OrdType") === "Limit On Open") {
            //   return <LOO />;
            // }  else if (getFieldValue("OrdType") === "TWAP") {
            //   return <TWAP />;
            // } else if (getFieldValue("OrdType") === "VWAP") {
            //   return <VWAP />;
            // } else if (getFieldValue("OrdType") === "POV") {
            //   return <POV />;
            // }
            else {
              return null;
            }
          }}
        </Form.Item>

        <Form.Item name="TradingSessionID" label="Session">
          <Select options={SessionSelect} />
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Space wrap>
            <Button type="primary" htmlType="submit" style={{ width: "130px" }}>
              Place Order
            </Button>
            <Button
              type="primary"
              htmlType="button"
              style={{ width: "130px" }}
              onClick={() => tradingForm.resetFields()}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewOrder;
