import { Tag } from "antd";

export const OrdTypeMap = {
  1: <Tag color="blue">MARKET</Tag>,
  2: <Tag color="geekblue">LIMIT</Tag>,
  3: <Tag color="orange">STOP</Tag>,
  4: <Tag color="gold">STOP LIMIT</Tag>,
  5: <Tag color="purple">MOC</Tag>,
  B: <Tag color="purple">LOC</Tag>,
  P: <Tag color="purple">Trailing Stop</Tag>,
};

export const OrdStatusMap = {
  0: <Tag color="default">New</Tag>,
  1: <Tag color="prossing">Partially filled</Tag>,
  2: <Tag color="blue">Filled</Tag>,
  4: <Tag color="cyan">Canceled</Tag>,
  5: <Tag color="error">Replaced</Tag>,
  6: <Tag color="default">Pending Cancel</Tag>,
  8: <Tag color="error">Rejected</Tag>,
  E: <Tag color="prossing">Pending Replace</Tag>,
  A: <Tag color="prossing">Pending new order</Tag>,
  C: <Tag color="error">Expired</Tag>,
};

export const SideMap = {
  1: <Tag color="green">BUY</Tag>,
  2: <Tag color="red">SELL</Tag>,
  5: <Tag color="magenta">SELL SHORT</Tag>,
};

export const TimeInForceMap = {
  0: <Tag color="default">Day</Tag>,
  1: <Tag color="default">GTC</Tag>,
  2: <Tag color="default">At opening</Tag>,
  3: <Tag color="default">IOC</Tag>,
  4: <Tag color="default">FOK</Tag>,
  6: <Tag color="default">GTD</Tag>,
};
