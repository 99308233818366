import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Space, Table, Button, Tag } from "antd";
import moment from "moment-timezone";
import OrderDetail from "../ordtype/OrderDetail";
import { getRecentOrders, getTodayOrders } from "../api/OrderHistoryAPI";
import { orderCancel } from "../api/OrderAPI";
import { OrdTypeMap, OrdStatusMap, SideMap } from "../ordtype/ComponentMaping";
import OrderReplaceModel from "./OrderReplaceModel";

const RecentOrder = () => {
  const queryClient = useQueryClient();
  const orderCancelMutation = useMutation({
    mutationFn: orderCancel,
    onSuccess: (data, variables, context) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["recent_orders"] }, { exact: true });
      }, 10000);
    },
  });

  const orders = useQuery({
    queryKey: ["recent_orders"],
    queryFn: getTodayOrders,
    staleTime: 1000, // 在這段時間內不做 refresh
  });

  const cancelOrderHandler = (record) => {
    const cancelOrd = {
      OrigClOrdID: record.ClOrdID,
      OrderID: record.OrderID,
      Symbol: record.Symbol,
      Side: record.Side,
    };
    orderCancelMutation.mutate(cancelOrd);
  };

  const replaceOrderHandler = (record) => {
    console.log("Replace");
    console.log(record);
  };

  const dataSource = orders?.data;

  const columns = [
    {
      title: "Account",
      dataIndex: "Account",
      align: "center",
    },
    {
      title: "VT Ref",
      dataIndex: "OrderID",
      align: "center",
    },
    // {
    //   title: "Client Ref",
    //   dataIndex: "ClOrdID",
    //   align: "center",
    // },
    {
      title: "Symbol",
      dataIndex: "Symbol",
      align: "center",
    },
    {
      title: "Qty",
      dataIndex: "OrderQty",
      align: "right",
      sorter: (a, b) => a.OrderQty - b.OrderQty,
    },
    {
      title: "Type",
      dataIndex: "OrdType",
      align: "center",
      render: (text, record) => (
        <>
          {OrdTypeMap[text]} {record.TimeInForce === "6" ? <Tag>GTD</Tag> : ""}
        </>
      ),
    },
    {
      title: "Side",
      dataIndex: "Side",
      align: "center",
      render: (text, record) => SideMap[text],
      sorter: (a, b) => a.Side - b.Side,
    },
    {
      title: "Price",
      dataIndex: "AvgPx",
      align: "right",
      render: (text, record) => (record.OrdStatus === "1" || record.OrdStatus === "2" ? text : "-"),
      sorter: (a, b) => a.AvgPx - b.AvgPx,
    },
    {
      title: "Status",
      dataIndex: "OrdStatus",
      align: "center",
      render: (text) => OrdStatusMap[text],
    },
    {
      title: "Sending Time",
      dataIndex: "SendingTime",
      align: "center",
      render: (text, record) => moment.utc(text).tz("Asia/Taipei").format("YYYY/MM/DD HH:mm:ss"),
      sorter: (a, b) => moment.utc(a.SendingTime) - moment.utc(b.SendingTime),
    },
    {
      title: "Comment",
      dataIndex: "Text",
      align: "center",
    },
    {
      title: "C/R",
      dataIndex: "Cancel",
      align: "center",
      render: (text, record) => {
        if (record.OrdStatus === "0" || record.OrdStatus === "A" || record.OrdStatus === "5") {
          return (
            <Space wrap>
              <Button size="small" onClick={() => cancelOrderHandler(record)} style={{ backgroundColor: "#f50" }}>
                Cancel
              </Button>
              {/* <Button size="small" onClick={() => replaceOrderHandler(record)} style={{ backgroundColor: "#2db7f5" }}>
                Replace
              </Button> */}

              <OrderReplaceModel record={record} />
            </Space>
          );
        }
      },
    },
  ];

  return (
    <div>
      <Table
        rowKey="OrderID"
        expandable={{
          expandedRowRender: (record) => <OrderDetail record={record} />,
        }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
      ;
    </div>
  );
};

export default RecentOrder;
