import React, { useEffect, useRef } from "react";
import { Input, Switch, Typography } from "antd";
import { useFIXStore } from "../store";

const FIXMonitor = () => {
  const FixMonitorMsg = useFIXStore((state) => state.FixMonitorMsg);
  const FixMonitorChk = useFIXStore((state) => state.FixMonitorChk);
  const setFixMonitorChk = useFIXStore((state) => state.setFixMonitorChk);
  const textMsg = useFIXStore((state) => state.textMsg);
  const setTextMsg = useFIXStore((state) => state.setTextMsg);
  const textareaRef = useRef(null);

  useEffect(() => {
    // let fixArr = textMsg.trim().split("\n");
    // fixArr.splice(100);
    // setTextMsg(fixArr.join("\n") + "\n" + FixMonitorMsg);
    setTextMsg(textMsg + "\n" + FixMonitorMsg);
    let scrollHeight = textareaRef.current.resizableTextArea.textArea.scrollHeight;
    let offsetHeight = textareaRef.current.resizableTextArea.textArea.offsetHeight;
    if (textareaRef.current) {
      textareaRef.current.resizableTextArea.textArea.scrollTop = scrollHeight - offsetHeight + 100;
    }
  }, [FixMonitorMsg]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Text strong>FIX Logs</Typography.Text>
        <Switch checked={FixMonitorChk} onChange={() => setFixMonitorChk(!FixMonitorChk)} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Input.TextArea
          ref={textareaRef}
          disabled
          value={textMsg}
          maxLength={500}
          style={{
            height: "600px",
            whiteSpace: "pre-line",
            color: "white",
            backgroundColor: "#3B3B3B",
          }}
        />
      </div>
    </>
  );
};

export default FIXMonitor;
