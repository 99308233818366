import axios from "axios";

export function getRecentOrders() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/recent_orders`).then((res) => res.data);
}

export function getTodayOrders() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/today_order`).then((res) => res.data);
}

export function getOrders() {
  return axios.get(`${process.env.REACT_APP_API_SERVER}/order`).then((res) => res.data);
}
