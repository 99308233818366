import { create } from "zustand";

export const useFIXStore = create((set) => ({
  FixMonitorChk: false,
  FixMonitorMsg: "",
  textMsg: "",
  socket: null,

  setFixMonitorChk: (chk) => set(() => ({ FixMonitorChk: chk })),
  setFixMonitorMsg: (msg) => set(() => ({ FixMonitorMsg: msg })),
  setTextMsg: (msg) => set(() => ({ textMsg: msg })),
  setSocket: (socket) => set(() => ({ socket })),
}));
