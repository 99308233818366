import { PageContainer } from "@ant-design/pro-components";
import { Row, Col } from "antd";
import BasketOrder from "./components/BasketOrder";
import OrderHistory from "./components/OrderHistory";
import Trading from "./components/Trading";
import SocketData from "./components/SocketData";
import Options from "./components/Options";

function App() {
  return (
    <div>
      <SocketData />
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 2, span: 20 }}
        >
          <PageContainer
            ghost
            header={{
              title: "FIX ORDER",
              breadcrumb: {},
            }}
            tabList={[
              // {
              //   tab: "SocketData",
              //   key: "SocketData",
              //   children: <SocketData />,
              // },
              {
                tab: "Equity Trading",
                key: "Trading",
                children: <Trading />,
              },
              {
                tab: "Options Trading",
                key: "Options",
                children: <Options />,
              },
              // {
              //   tab: "Order History",
              //   key: "Order History",
              //   children: <OrderHistory />,
              // },
              {
                tab: "Basket",
                key: "Basket",
                children: <BasketOrder />,
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

export default App;
