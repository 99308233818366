import React from "react";
import { ProCard } from "@ant-design/pro-components";
import { Row, Col, Divider } from "antd";
import NewOrder from "./NewOrder";
import RecentOrder from "./RecentOrder";
import FIXMonitor from "./FIXMonitor";

const Trading = () => {
  return (
    <>
      <Row gutter={15}>
        <Col span={12}>
          <ProCard bordered style={{ height: "650px" }}>
            <NewOrder />
          </ProCard>
        </Col>
        <Col span={12}>
          <ProCard bordered style={{ height: "650px" }}>
            <FIXMonitor />
          </ProCard>
        </Col>
      </Row>

      <Divider />
      <RecentOrder />
    </>
  );
};

export default Trading;
