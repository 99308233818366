import React from "react";
import { Descriptions } from "antd";
import { OrdTypeMap, OrdStatusMap, SideMap, TimeInForceMap } from "./ComponentMaping";
import moment from "moment-timezone";

const OrderDetail = ({ record }) => {
  return (
    <div style={{ marginLeft: 50 }}>
      <Descriptions size="small">
        <Descriptions.Item label="Account">{record.Account}</Descriptions.Item>
        <Descriptions.Item label="Client Ref.">{record.ClOrdID}</Descriptions.Item>
        <Descriptions.Item label="Orbis Ref.">{record.OrderID}</Descriptions.Item>
        <Descriptions.Item label="Symbol">{record.Symbol}</Descriptions.Item>
        <Descriptions.Item label="Qty">{record.OrderQty}</Descriptions.Item>
        <Descriptions.Item label="Order Price">{record.Price ? "$" + record.Price : "-"}</Descriptions.Item>
        <Descriptions.Item label="Filled Price">
          {record.OrdStatus === "1" || record.OrdStatus === "2" ? "$" + record.AvgPx : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Side">{SideMap[record.Side]}</Descriptions.Item>
        <Descriptions.Item label="Order Time">
          {moment.utc(record.SendingTime).tz("Asia/Taipei").format("YYYY/MM/DD HH:mm:ss.SSS")}
        </Descriptions.Item>
        <Descriptions.Item label="Transact Time">
          {moment.utc(record.TransactTime).tz("Asia/Taipei").format("YYYY/MM/DD HH:mm:ss.SSS")}
        </Descriptions.Item>

        <Descriptions.Item label="Order Status">{OrdStatusMap[record.OrdStatus]}</Descriptions.Item>
        <Descriptions.Item label="Order Type">{OrdTypeMap[record.OrdType]}</Descriptions.Item>
        <Descriptions.Item label="Time In Force">{TimeInForceMap[record.TimeInForce]}</Descriptions.Item>
        <Descriptions.Item label="Comment">{record.Text}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OrderDetail;
