import React, { useState } from "react";
import { ModalForm, ProFormText } from "@ant-design/pro-components";
import { Button, message, Typography, Space } from "antd";
import { orderReplace } from "../api/OrderAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const OrderReplaceModel = ({ record }) => {
  const queryClient = useQueryClient();
  const orderReplaceMutation = useMutation({
    mutationFn: orderReplace,
    onSuccess: (data, variables, context) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["recent_orders"] }, { exact: true });
      }, 9000);
    },
  });

  const [ordValue, setOrdValue] = useState({ qty: record?.OrderQty, Price: record?.Price });

  return (
    <div>
      <ModalForm
        width={450}
        title={
          <Typography.Title level={4} style={{ marginBottom: "25px" }}>
            Order Replacement
          </Typography.Title>
        }
        layout="horizontal"
        labelCol={{ span: 4 }}
        trigger={
          <Button size="small" type="primary" style={{ backgroundColor: "#2db7f5" }}>
            Replace
          </Button>
        }
        submitter={{
          searchConfig: {
            submitText: "Confirm",
            resetText: "Cancel",
          },
        }}
        onFinish={async () => {
          const replaceOrd = {
            OrigClOrdID: record.ClOrdID,
            OrderID: record.OrderID,
            OrderQty: ordValue.qty,
            OrdType: record.OrdType,
            Symbol: record.Symbol,
            Side: record.Side,
            Price: ordValue.price,
          };
          orderReplaceMutation.mutate(replaceOrd);
          message.success("Sending order replacement");
          return true;
        }}
      >
        <ProFormText
          width="md"
          name="Quentity"
          label="Quentity"
          value={ordValue.qty}
          onChange={(e) => setOrdValue({ ...ordValue, qty: e.target.value })}
        />
        <ProFormText
          width="md"
          name="Price"
          label="Price"
          value={ordValue.Price}
          onChange={(e) => setOrdValue({ ...ordValue, price: e.target.value })}
        />
      </ModalForm>
    </div>
  );
};

export default OrderReplaceModel;
