import { Typography } from "antd";
const { Text } = Typography;

export const SideSelect = [
  { value: "1", label: "Buy" },
  { value: "2", label: "Sell" },
  { value: "5", label: "Sell Short" },
];

export const OptionsSideSelect = [
  { value: "1", label: "Buy Open" },
  { value: "2", label: "Sell Open" },
  { value: "BC", label: "Buy to Cover" },
  { value: "SC", label: "Sell to Cover" },
];

export const OrdTypeSelect = [
  { value: "1", label: "Market" },
  { value: "2", label: "Limit" },
  { value: "3", label: "Stop" },
  { value: "4", label: "Stop Limit" },
  { value: "5", label: "Market On Close" },
  { value: "B", label: "Limit On Close" },
  { value: "P", label: "Trailing Stop" },
  // "Notional Market",
  // "Notional Limit",
  // "Market On Open",
  // "Limit On Open",
  // "TWAP",
  // "VWAP",
  // "POV",
];

export const MyOrdTypeSelect = [
  { value: "1", label: "Market" },
  { value: "2", label: "Limit" },
  { value: "3", label: "Stop" },
  { value: "4", label: "Stop Limit" },
  { value: "5", label: "Market On Close" },
  { value: "B", label: "Limit On Close" },
  { value: "P", label: "Trailing Stop" },
  { value: "NM", label: "Notional Market" },
  { value: "NL", label: "Notional Limit" },
  // "Market On Open",
  // "Limit On Open",
  // "TWAP",
  // "VWAP",
  // "POV",
];

export const SessionSelect = [
  {
    value: "PRE",
    label: (
      <>
        PRE <Text type="secondary">(04:00am ~ 09:30am)</Text>
      </>
    ),
  },
  {
    value: "CORE",
    label: (
      <>
        CORE <Text type="secondary">(09:30am ~ 16:00pm)</Text>
      </>
    ),
  },
  {
    value: "POST",
    label: (
      <>
        POST <Text type="secondary">(16:00pm ~ 20:00pm)</Text>
      </>
    ),
  },
  {
    value: "PRE+CORE",
    label: (
      <>
        PRE & CORE <Text type="secondary">(04:00am ~ 16:00pm)</Text>
      </>
    ),
  },
  {
    value: "CORE+POST",
    label: (
      <>
        CORE & POST <Text type="secondary">(09:30am ~ 20:00pm)</Text>
      </>
    ),
  },
  {
    value: "ALL",
    label: (
      <>
        ALL <Text type="secondary">(04:00am ~ 20:00pm)</Text>
      </>
    ),
  },
  {
    value: "NIGH",
    label: (
      <>
        NIGHT <Text type="secondary">(20:00pm ~ 04:00am)</Text>
      </>
    ),
  },
];
