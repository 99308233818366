import axios from "axios";

export async function newOrder(order) {
  return axios.post(`${process.env.REACT_APP_API_SERVER}/new_order`, order).then((res) => res.data);
}

export async function basketOrder(order) {
  return axios.post(`${process.env.REACT_APP_API_SERVER}/basket_order`, order).then((res) => res.data);
}

export async function orderCancel(order) {
  console.log(order);
  return axios.post(`${process.env.REACT_APP_API_SERVER}/order_cancel`, order).then((res) => res.data);
}

export async function orderReplace(order) {
  return axios.post(`${process.env.REACT_APP_API_SERVER}/order_replace`, order).then((res) => res.data);
}
