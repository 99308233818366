import React from "react";
import { ProCard } from "@ant-design/pro-components";
import { Row, Col, Divider } from "antd";
import BasketOrderUpload from "./BasketOrderUpload";
import RecentOrder from "./RecentOrder";
import FIXMonitor from "./FIXMonitor";

const BasketOrder = () => {
  return (
    <>
      <Row gutter={15}>
        <Col span={13}>
          <ProCard bordered style={{ height: "650px" }}>
            <BasketOrderUpload />
          </ProCard>
        </Col>
        <Col span={11}>
          <ProCard bordered style={{ height: "650px" }}>
            <FIXMonitor />
          </ProCard>
        </Col>
      </Row>

      <Divider />
      <RecentOrder />
    </>
  );
};

export default BasketOrder;
