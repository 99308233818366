import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button, Space, Table } from "antd";
import Papa from "papaparse";
import { SideSelect, OrdTypeSelect } from "../Selection/Select";
import { basketOrder } from "../api/OrderAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash-es";
import { useFIXStore } from "../store";

const verifyCsvValue = (data) => {
  let header = ["Account", "Symbol", "Side", "OrderQty", "CashOrderQty", "OrdType", "Price"];
  let csvData = data.data;

  // Check Header
  if (!isEqual(data.meta.fields, header)) {
    return false;
  }

  let resData = [];
  for (let i = 0; i < csvData.length; i++) {
    let item = {};

    // Check Account
    if (csvData[i].Account !== "") {
      item = { ...item, Account: csvData[i].Account };
    } else {
      return false;
    }

    // Check Symbol
    if (csvData[i].Symbol !== "") {
      item = { ...item, Symbol: csvData[i].Symbol };
    } else {
      return false;
    }

    // Check CashOrderQty
    if (csvData[i].CashOrderQty !== "" || Number(csvData[i].CashOrderQty) !== 0) {
      item = { ...item, CashOrderQty: Number(csvData[i].CashOrderQty) };
    }

    // Check OrderQty
    if (csvData[i].OrderQty !== "") {
      item = { ...item, OrderQty: Number(csvData[i].OrderQty) };
    } else {
      return false;
    }

    // Check Price
    if (csvData[i].Price !== "") {
      item = { ...item, Price: Number(csvData[i].Price) };
    }

    // Check Side
    let sideVar = csvData[i].Side.trim().toUpperCase();
    if (sideVar === "BUY" || sideVar === "SELL") {
      item = { ...item, Side: Number(SideSelect.filter((v) => v.label.toUpperCase() === sideVar)[0].value) };
    } else {
      return false;
    }

    // Check OrdType
    let ordTypeVar = csvData[i].OrdType.trim().toUpperCase();
    if (ordTypeVar === "MARKET" || ordTypeVar === "LIMIT") {
      item = { ...item, OrdType: Number(OrdTypeSelect.filter((v) => v.label.toUpperCase() === ordTypeVar)[0].value) };
    } else {
      return false;
    }
    resData.push(item);
  }

  return resData;
};

const beforeUploadHandler = (file, setOrders) => {
  const isFileTypeCorrect = file.type === "text/csv";
  if (!isFileTypeCorrect) {
    message.error("File type incorrect! csv file only!");
  }
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = (e) => {
    var contents = e.target.result;
    // const csvData = Papa.parse(contents.trim(), { header: true }).data;
    const csvData = Papa.parse(contents.trim(), { header: true });
    // console.log(csvData.meta.fields);
    // console.log(csvData);

    var result = verifyCsvValue(csvData);
    if (result) {
      setOrders(result);
    } else {
      message.error("Incorrect file data!!");
    }
  };
  return false;
};

const OrderTable = ({ orders }) => {
  const columns = [
    {
      title: "Symbol",
      dataIndex: "Symbol",
      align: "center",
    },
    {
      title: "Side",
      dataIndex: "Side",
      align: "center",
      render: (text) => SideSelect.filter((v) => v.value === String(text))[0].label,
    },
    {
      title: "Qty",
      dataIndex: "OrderQty",
      align: "center",
    },
    {
      title: "Notional",
      dataIndex: "CashOrderQty",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "OrdType",
      align: "center",
      render: (text) => OrdTypeSelect.filter((v) => v.value === String(text))[0].label,
    },
    {
      title: "Price",
      dataIndex: "Price",
      align: "center",
    },
  ];
  const dataSource = orders;

  return (
    <Table
      rowKey={(record) => record.Symbol + record.Side + record.OrderQty + record.OrdType + record.Price}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size="middle"
      bordered
      scroll={{ y: 360 }}
    />
  );
};

const BasketOrderUpload = () => {
  const FixMonitorMsg = useFIXStore((state) => state.FixMonitorMsg);
  const SAMPLE_FILE_URL = `${process.env.REACT_APP_URL}/sample.csv`;
  const [orders, setOrders] = useState(null);
  const queryClient = useQueryClient();
  const createBasketMutation = useMutation({
    mutationFn: basketOrder,
    onSuccess: (data, variables, context) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["recent_orders"] }, { exact: true }); // Invalidate query
      }, 30000);
    },
  });

  return (
    <div>
      <div style={{ height: "140px" }}>
        <Upload.Dragger
          name="file"
          multiple={false}
          maxCount={1}
          showUploadList={false}
          accept=".csv"
          beforeUpload={(file) => beforeUploadHandler(file, setOrders)}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload (csv only,{" "}
            <a href={SAMPLE_FILE_URL} target="_blank" download="sample.csv">
              sample.csv
            </a>
            )
          </p>
          {/* <p className="ant-upload-hint">Support for csv, excel files</p> */}
        </Upload.Dragger>
      </div>
      {orders ? (
        <div style={{ marginTop: 15 }}>
          <OrderTable orders={orders} />
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
            <Space>
              <Button type="primary" onClick={() => setOrders(null)}>
                Clear Orders
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  createBasketMutation.mutate(orders);
                }}
              >
                Place Orders
              </Button>
            </Space>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default BasketOrderUpload;
