import { useEffect } from "react";
import { useFIXStore } from "../store";
import { FIXSocket } from "../api/WebSocketAPI";

const SocketData = () => {
  const FixMonitorChk = useFIXStore((state) => state.FixMonitorChk);
  const setFixMonitorMsg = useFIXStore((state) => state.setFixMonitorMsg);
  const socket = useFIXStore((state) => state.socket);
  const setSocket = useFIXStore((state) => state.setSocket);
  const setTextMsg = useFIXStore((state) => state.setTextMsg);

  useEffect(() => {
    if (FixMonitorChk == true) {
      let ws = FIXSocket();

      ws.onopen = () => {
        console.log("WebSocket connection opened");
      };
      ws.onmessage = (e) => {
        setFixMonitorMsg(JSON.parse(e.data));
      };
      ws.onclose = () => {
        console.log("WebSocket connection closed");
      };
      setSocket(ws);
    } else {
      if (socket) {
        socket.close();
        setSocket(null);
        setTextMsg("");
      }
    }
  }, [FixMonitorChk]);
};

export default SocketData;
